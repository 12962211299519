<template>
  <div class="card flex-grow flex-shrink-0 lg:max-w-md xl:max-w-lg flex " id="printref">
    <div class="bg-primary p-3 px-4 font-bold text-white-text text-md">
      {{ title }}
    </div>
    <div class="card-body rounded-b-box mb-8 bg-base-300 p-4">
      <div class="flex w-full flex-col">
        <template v-if="loading">
          <div class="w-full flex my-32">
            <Loader class="mx-auto" />
          </div>
        </template>

        <template v-else>
          <template v-if="data && data.length == 0">
            <div class="alert alert">
              <Empty />
            </div>
          </template>
          <template v-else>
            <!-- FORM FIELD  -->
            <div class="flex" v-for="form in data" :key="form.id + form.label + form.value">
              <div class="flex w-full items-start gap-x-2 mb-2 flex-shrink-0" v-if="true"> <!-- v-if=getFieldVisibility(form)" -->
                <!-- // CANDIDATE / REFEREE SVG -->
                <span
                  class="
                    align-baseline
                    justify-start
                    w-10
                    flex flex-col
                    mt-10
                    flex-shrink-0
                  "
                >
                <candidateSvg class="w-10 h-10 mt-2 print--none"  v-if="form.icon != 'analyst'"/>
                  <refreeSvg
                    class="w-10 p-2 h-10 print--none"
                    :key="form.correct"
                    v-else-if=" form.icon === 'referee' && form.correct === 'incorrect' && form.icon != 'analyst'"                  />
                  <analyst
                    class="w-10 p-2 h-10 print--none"
                    v-else-if="form.is_comment && commentBoxKey && form.icon == 'analyst'"
                  />
                </span>
                <div
                  class="flex flex-grow flex-col gap-x-3 mb-4 justify-between"
                >
                <!-- LABEL  -->
                  <div >
                    <span
                    :class="{'signRef':form.type=='signature'}"
                    id="signLabel"
                      class="
                        flex-shrink-0
                        font-medium
                        leading-tight
                        text-base
                        mb-2
                        text-base-content
                      "
                      v-text="form.label"
                      v-if="!['label'].includes(form.type)"
                    />
                    <span v-if="form.mandatory" class="ml-2 text-red-500 print--none">*</span>
                  </div>
                  <div
                    class="flex-grow border mt-1 customborder"
                    :class="[
                      form.correct === 'correct'
                        ? 'border-green-600 rounded-2xl'
                        : form.compare && form.correct === 'incorrect'
                        ? 'border-red-600 rounded-t-2xl'
                        : hasFormError && form.mandatory && !form.compare && !form.value && form.can_approve
                        ? 'border-red-600 rounded-2xl'
                        : 'border-transparent rounded-2xl',
                      ['text', 'email', 'date', 'select', 'textarea', 'multi-select','signature'].includes(
                        form.type
                      )
                        ? 'bg-white'
                        : 'bg-white p-4',
                      form.type === 'label' || form.type === 'date-range' ? 'date-range ' : '',
                    ]"
                   >
                    <!-- // for text input  -->
                    <!-- * as dicsussed with Mukul, candidate input should be disabled   -->
                    <FormulateInput
                      class="w-full print-border"
                      v-if="form.type === 'text'"
                      :type="form.type"
                      :placeholder="form.placeholder"
                      @change="setValue($event, form.id)"
                      @input="handleError($event, form.id)"
                      :options="form.options"
                      :required="form.required"
                      :value="form.value"
                      :disabled="disabled || getDisabledCondition(form)"
                    />
                    <!-- for comments -->
                    <FormulateInput
                    v-else-if="form.type === 'textarea' && form.is_comment"
                    v-show="form.is_comment"
                    :type="form.type"
                    :placeholder="form.placeholder"
                    @change="setValue($event, form.id)"
                    @input="handlechange($event, form.id)"
                    :value="form.value"
                    class="w-full print-border"
                    :disabled="disabled || form.compare || !form?.api_data?.editable"
                    />
                    <!-- // for other type inputs  -->
                    <select
                      v-else-if="form?.type == 'select' && form?.is_comment"
                      class="select select-bordered w-full"
                      style="background-color: rgb(255, 255, 255)"
                      @change="setValue($event, form.id)"
                      :value="getValue(form.value,form.type)"
                      :disabled="disabled || form.compare || !form?.api_data?.editable"
                    >
                    <option
                        selected
                        hidden
                      >
                        Please select a value
                      </option>
                      <option 
                        v-for="(option, idx) in getOptions(form)"
                        :key="idx"
                        :value="option.label"
                      >
                        {{option.label}}
                      </option>
                    </select>
                    <FormulateInput
                      :class="{'RadioBtn': form.type == 'radio','DropDownBtn': form.type == 'select','MultiDropDownBtn':form.type =='multi-select','CheckboxBtn':form.type =='checkbox','TextareaBtn':form.type =='textarea'}"
                      class="w-full print-border "
                      v-else-if="form.is_comment == 'false' && form.type !== 'date' && form.type != 'select'"
                      :type="form.type"
                      :label="form.label"
                      :showLabel="true"
                      :placeholder="form.placeholder"
                      @input="setNewValue($event, form?.id)"
                      :options="getOptions(form)"
                      :required="form.required"
                      :value="getValue(form.value,form.type)"
                      :ip="ip"
                      :isSignWithIp="showIp(form)"
                      @currValue = "handleCurrValue"
                      :disabled="disabled || form.compare || !form?.api_data?.editable"
                    />
                    <!-- :disabled="disabled || form.compare || !form?.api_data?.editable" -->
                    <DatePicker
                      class="w-full print-border refdate"
                      value-type="YYYY-MM-DD"
                      format="DD-MMM-YYYY"
                      :clearable="false"
                      v-else-if="form.type == 'date' && form.is_comment == 'false'"
                      :label="form.label"
                      :showLabel="true"
                      :placeholder="form.placeholder"
                      @input="setNewValue($event, form?.id)"
                      :options="getOptions(form)"
                      :required="form.required"
                      :value="getValue(form.value,form.type)"
                      :disabled="disabled || getDisabledCondition(form)"
                      :ip="ip"
                      :isSignWithIp="showIp(form)"
                      @currValue = "handleCurrValue"
                      />
                  </div>
                  <div class="flex items-center">
                    <template  v-if="form.type === 'signature' && ip && showIp(form) && hasSignature">
                      <span class="ml-2 mt-2">
                        {{ ip }} 
                      </span>
                    </template>
                  </div>
                  <!-- /* incorrect input  -->
                  <div
                    class="flex-grow relative border-t-0 rounded-b-2xl"
                    :class="getClasses(form.type)"
                    v-if="form.correct === 'incorrect' && form.compare"
                  >
                    <FormulateInput
                      class="w-full print-border"
                      v-if="form.type !== 'date'"
                      @input="setDateNewValue($event, form)"
                      @change="setIncorrectVal($event, form.id)"
                      :type="form.type"
                      :value="getFormattedValue(form, 'correct_value')"
                      :options="getOptions(form, true)"
                      placeholder="Enter correct information"
                      :required="true"
                      :disabled="disabled || !form?.api_data?.editable"
                    />
                    <DatePicker
                      class="w-full print-border refdate"
                      value-type="YYYY-MM-DD"
                      format="DD-MMM-YYYY"
                      :clearable="false"
                      v-if="form.type == 'date'"
                      @input="setDateNewValue($event, form)"
                      :value="getFormattedValue(form, 'correct_value')"
                      :options="getOptions(form, true)"
                      placeholder="dd-mmm-yyyy"
                      :required="true"
                      :disabled="disabled || !form?.api_data?.editable"
                      />
                    
                  </div>
                  <!-- VALIDATION  -->
                  <span
                    v-if="
                      form.correct === 'incorrect' &&
                      form.compare &&
                      form.correct_value != '' &&
                      !isEmailValid(form.correct_value) &&
                      form.type === 'email'
                    "
                    class="text-red-800 inline-block mb-2 py-1 px-4 text-xs"
                    >Please enter valid email</span
                  >
                  <span
                    v-else-if="
                      form.correct === 'incorrect' &&
                      form.compare &&
                      form.correct_value == ''
                    "
                    class="text-red-800 inline-block mb-2 py-1 px-4 text-xs"
                    >Please enter correct information</span
                  >
                </div>
                <!-- Toggle correct incorrect value  -->
                <div class="flex-shrink-0 self-end mb-5" v-if="form.compare">
                  <ToggleCheck
                  class="print--none"
                    v-if="form.compare"
                    :value="form.correct"
                    multiple
                    @change="handlechange($event, form.id)"
                    :disabled="disabled || !form?.api_data?.editable"
                  />
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
    <!-- SHOW ACTION BUTTONS  -->
    <div class="footer justify-center px-4 print--none" v-if="showAction">
      <Button
        text="Cancel"
        type="primary"
        @click="$emit('cancelForm')"
        :disabled="isSaveDisabled"
      />
      <Button text="Submit" :isLoader="isLoader" :loader="loader" :disabled="isSaveDisabled" :disableAfterSubmit="disableOnSubmit"  type="primary" @click="$emit('handleForm')" />
    </div>
  </div>
</template>

<script>
import Button from "@/components/button";
import ToggleCheck from "@shared/toggle-switch";
import Empty from "@shared/empty";
import Loader from "@shared/loader";
import refreeSvg from "@shared/assets/svg/refree.svg";
import candidateSvg from "@shared/assets/svg/candidate.svg";
import { isJson } from '@shared/components/form-builder/utils';
import analyst from "@shared/assets/svg/analyst.svg"
import axios from "@/axios"
import DatePicker from "vue2-datepicker";
import { appList } from '@shared/components/form-builder/utils/index.js';
// import Vue from "vue"

// Vue.use(VueFormulate)

const controlValueConf = {
  [appList.TENANT]: 'value',
  default: 'correct_value',
}

export default {
  name: "comparison-data",
  components: { Button, ToggleCheck, refreeSvg, candidateSvg, Loader, Empty , analyst , DatePicker},
  props: {
    app: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Title",
    },
    showAction: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    commentBoxKey:{
      type: Boolean,
      default: true,
    },
    isSaveDisabled: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasFormError: {
      type: Boolean,
      default: false
    },
    disableOnSubmit: {
      type: Boolean,
    },
    isLoader: {
      type: Boolean,
    },
    loader: {
      type: Boolean,
    }
  },
  data() {
    return {
      value: "unselected",
      ip: "",
      isSigned: ""
    };
  },
  mounted() {
    this.fetchIpAddress()
  },
  methods: {
    /* CHECK IF FIELD IS VISIBLE 
    / BASED ON CONDITIONAL DEPENDENCIES
    / RETURN TRUE TO GET THE DEFAULT BEHAVIOUR
    */
    getFieldVisibility(field) {
      if (field && field.is_conditional && field.value === null) {
        // if field is condiitonal check the conditon
        let visible = false
        const controlField = this.data.find(el => el.id === field.condition.control_field_id)
        console.log('controlField :>> ', controlField, field);

        // some properties aren't provided for particular apps so we need to use other:
        const controlValueKey = controlValueConf[this.app] || controlValueConf.default;

        visible = (controlField && controlField[controlValueKey] === field.condition.control_field_value) 
        return visible

      } else return true

    },
    getFormattedValue(form, key) {
      let value = form[key]
      if (["checkbox"].includes(form.type) && isJson(form.type)) return JSON.parse(value);
      else return value;
    },
    getClasses(type) {
      let classes = "";
      // [form.type === 'date-range' ? 'border mt-2 ' : 'bg-white  border-0', {'date-range-error': form.type === 'date-range'} ]
      if (type === "date-range") {
        classes += " border mt-2 date-range-error";
      } else classes += " bg-white  border-0 ";
      if (!["text", "email", "select","multi-select", "date-range","date", "signature","textarea"].includes(type)) {
        classes += " p-4 ";
      }
      return classes;
    },
    getOptions({options, type}, isCustom = false) {
      if (type !== 'checkbox') {
        return options.map((el) => ({
          label: el.label,
          value: el.value,
          id: isCustom ? el.id : `custom` + el.id,
        }));
      } else {
        return options.map((el) => ({
          ...el,
          id: isCustom ? el.id : `custom` + el.id,
        }));
      }
    },
    // SET correct / Incorrect toggle value
    handlechange(val, index) {
      //   this.value = val
      this.$emit("handleCompare", { value: val, id: index, key: 'handleError'})
      this.$emit("handleCompare", { value: val, id: index, key: "correct" });
      this.$emit("handleCompare", {
        value: true,
        id: index,
        key: "ref_action",
      });
    },

    /**
     * Set the ref_action_value / correct_value 
     * 
     */
    setIncorrectVal(event, index) {
      let value = event.target.value;
      this.$emit("handleCompare", { value, id: index, key: "correct_value" });
      this.$emit("handleCompare", {
        value: true,
        id: index,
        key: "ref_action",
      });
    },
    /**
     * Set Value of the field of the ID provided
     */
    setValue(e, id) {
      let value = e.target.value;
      this.$emit("handleCompare", { value, id: id, key: "value" });
      this.$emit("handleCompare", { value: true, id: id, key: "ref_action" });
    },

    /**
     * Handle error for referee form
     */
    handleError(val, id) {
      this.$emit("handleCompare", { value: val , id: id, key: 'handleError'})
    },

    /**
     * Set Value of the field of the ID provided
     */
    setNewValue(value, id) {
      this.$emit("handleCompare", { value, id: id, key: 'handleError'})
      this.$emit("handleCompare", { value, id: id, key: "value" });
      this.$emit("handleCompare", { value: true, id: id, key: "ref_action" });
    },
    /**
     * Set Value of the field of the DATE/ DATERANGE FIELD 
     */
    setDateNewValue(value, form) {
      if (form.type === ["checkbox"]) value = JSON.stringify(value);
      this.$emit("handleCompare", { value, id: form.id, key: "correct_value" });
      this.$emit("handleCompare", {
        value: true,
        id: form.id,
        key: "ref_action",
      });
    },
    getValue(value, type) {
      if (type === 'checkbox') {
        return value
      }
      else return value
    },
    async fetchIpAddress() {
      this.loader = true
      if (this.ip === '' ) {
        try {
          const { data } = await axios.get(`user-auth/get-ip`, {
            headers: {
              'Authorization':`Bearer ${this.token}`
            },
          })
          this.ip = data.ip || ''
          console.log(">> ip",data.ip);
        } catch (error) {
          console.log('error :>> ', error);
        }
      }
      // this.getValue = value
      let result = {
        ...this.getValue,
        ip: this.showIp ? this.ip : null
      }
      this.$emit("input", JSON.stringify(result));
      this.loader = false
    },
    getDisabledCondition(form){
      if(form?.api_data?.is_comment){
        return false;
      }
      if(form?.api_data?.editable){
        if(form?.compare){
          return true;
        }
        else{
          return false;
        }
      } else {
        return true;
      }
    },
    showIp(fieldData) {
      return fieldData && fieldData.meta && JSON.parse(fieldData.meta)?.get_ip_address || false;
    },
    handleCurrValue(val){
      this.isSigned = val ? true : false;
    },
    isEmailValid(email){
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailRegex.test(email)
    }
  },
  computed:{
    hasSignature(){
      return this.isSigned;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .formulate-input .formulate-input-element {
    max-width: 40em !important;
    margin-bottom: 0 !important;
    input,
    select,
    textarea {
      font-size: 1rem;
      padding: 1rem 0.75rem;
      border-color: transparent;
      &:disabled {
        @apply text-gray-600;
      }
    }
  }
  .formulate-input .formulate-input-element.formulate-input-element--radio,
  .formulate-input .formulate-input-element.formulate-input-element--checkbox {
    width: auto !important;
    margin-right: 1rem;
  }
  // [data-v-cb4ea1d8] .formulate-input input[type=email], [data-v-cb4ea1d8] .formulate-input input[type=text]
  .formulate-input-wrapper {
    .formulate-input-element .formulate-input-element--file {
      margin-bottom: 0;
    }
    .formulate-input-upload-area-mask {
      border-radius: 1rem;
    }
  }
  .mx-datepicker {
    width: auto !important;
    .mx-input:disabled, .mx-input.disabled {
      color: #444;
      background-color: #f2f2f2;
      border-color: #999;
      cursor: not-allowed;
    }
  }
  .refdate{
    width: 100% !important;
    .mx-input:disabled, .mx-input.disabled {
      color: #444;
      background-color: #fff;
      border-color: #999;
      cursor: not-allowed;
    }
  }
  .mx-input {
    border-radius: 1rem !important;
    min-height: 3rem !important;
  }

  .date-range {
    padding: 0 !important;
    border: 0 !important;
    background: #0000 !important;
  }


  .multiselect {
    padding: 0.3rem 0;
    background:transparent !important;
    // height: 3rem;
    .multiselect__select{
      background:transparent !important;
    }
    .multiselect__tags {
    border: 0;
    border-radius: 1rem;
    .multiselect__tag {
      @apply bg-blue-500;
      line-height: 1rem !important;
      @apply text-base;
      @apply rounded-lg;
      &-icon {
        @apply text-white-text;
        &:hover {
          @apply bg-primary-focus;
        }
      }
    }
  }
  .multiselect__option--highlight {
    @apply bg-primary-focus;
  }
  }
  @media print {
    .print--none {
      display: none !important;
    }
  }
  
}
</style>